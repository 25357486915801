import React from 'react'
import styled from 'styled-components'
import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import Image from '../Image'
import FloorplanTable from '../floorplan/FloorplanTable'
import FloorplanFooter from '../floorplan/FloorplanFooter'

function FloorplanSegment( {
  anchorId,
  title,
  image,
  table,
  footer,
} ) {
  return (
    <PageSegmentBackground
      anchorId={ anchorId }
      segmentType="floorplans"
      color="realWhite"
      hasSectionTitle={ title }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            align="left"
          />
          {image && (
            <StyledImageFrame>
              <div>
                <Image
                  src={ image.url }
                  alt={ image.alt }
                  dateModified={ image.dateModified }
                  width={ image.width }
                  height={ image.height }
                />
              </div>
            </StyledImageFrame>
          )}

          {table && (
            <FloorplanTable rows={ table } />
          )}

          {footer?.map( f => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <FloorplanFooter key={ f.id } { ...f } />
          ) )}
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

const StyledImageFrame = styled.div`
  overflow: scroll;
  margin-left: calc( var( --container-margin-width ) * -1 );
  padding-left: var( --container-margin-width );
  margin-right: calc( var( --container-margin-width ) * -1 );
  padding-right: var( --container-margin-width );
  > div {
    min-width: 100vh;
  }
`

export default FloorplanSegment
