import styled from 'styled-components'
import Background from '../layout/Background'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import InfoPointsSegment from './InfoPointsSegment'
import VideoCtaSegment from './VideoCtaSegment'
import { StyledVideoCta } from '../VideoCta'
import PackagesSegment from './PackagesSegment'
import TestimonialsSegment from './TestimonialsSegment'
import AccordionSegment from './AccordionSegment'
import TextCtaSegment from './TextCtaSegment'
import ExpertsSegment from './ExpertsSegment'
import ContactSegment from './ContactSegment'
import LogosSegment from './LogosSegment'
import PostcardsSegment from './PostcardsSegment'
import LargePostcardsSegment from './LargePostcardsSegment'
import ProcessStepsSegment from './ProcessStepsSegment'
import CompanyFooter from '../CompanyFooter'
import CardsSegment from './CardsSegment'
import LogoGridSegment from './LogoGridSegment'
import PropertyListingsSegment from './PropertyListingsSegment'
import PropertyListingsLargeSegment from './PropertyListingsLargeSegment'
import StorySegment from './StorySegment'
import ProcessStepsSimpleSegment from './ProcessStepsSimpleSegment'
import HighlightsSegment from './HighlightsSegment'
import FloorplanSegment from './FloorplanSegment'
import LocalAreaSegment from './LocalAreaSegment'
import ColumnsSegment from './ColumnSegment'

function PageSegments( { pageSegments, before = null, after = null } ) {
  if ( !pageSegments ) {
    return null
  }

  return (
    <StyledPageSegments>
      {before}
      {pageSegments.map( ( pageSegment, i ) => {
        const additionalBottomPadding = pageSegments[ i + 1 ]?.typeHandle === 'videoCta' ? 6 : 0

        const anchorId = pageSegment.anchorId || pageSegment.id

        if ( pageSegment.typeHandle === 'accordion' ) {
          return (
            <AccordionSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              image={ pageSegment.mainImage[ 0 ] }
              buttonLabel={ pageSegment.buttonLabel }
              buttonLink={ pageSegment.buttonLink }
              buttonIcon={ pageSegment.buttonIcon }
              accordionStyle={ pageSegment.accordionStyle }
              accordionLayout={ pageSegment.accordionLayout }
              accordion={ pageSegment.accordion }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'cards' ) {
          return (
            <CardsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              alignment={ pageSegment.alignment ? 'center' : 'left' }
              mainImage={ pageSegment.mainImage }
              cards={ pageSegment.cards }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'contact' ) {
          return (
            <ContactSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              contactCards={ pageSegment.contactCards }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'experts' ) {
          return (
            <ExpertsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              experts={ pageSegment.experts }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'footer' ) {
          return (
            <CompanyFooter
              key={ pageSegment.id }
              anchorId={ anchorId }
              bodyText={ pageSegment.bodyText }
              showLanguageSwitcher={ !pageSegment.hideLanguagePicker }
            />
          )
        }

        if ( pageSegment.typeHandle === 'infoPoints' ) {
          return (
            <InfoPointsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              alignment={ pageSegment.infoPointsAlignment ? 'center' : 'left' }
              infoPoints={ pageSegment.infoPoints }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'largePostcards' ) {
          return (
            <LargePostcardsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              postcards={ pageSegment.largePostcards }
              additionalBottomPadding={ additionalBottomPadding }
              headingBackgroundColor={ pageSegment.largeCardsSectionBGColor }
            />
          )
        }

        if ( pageSegment.typeHandle === 'logoGrid' ) {
          return (
            <LogoGridSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              linkedLogos={ pageSegment.linkedLogos }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'partnerLogos' ) {
          return (
            <LogosSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              logos={ pageSegment.partnerLogos }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'packages' ) {
          return (
            <PackagesSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              bodyText={ pageSegment.bodyText }
              packages={
                pageSegment.packageEntries?.length > 0
                  ? pageSegment.packageEntries
                  : pageSegment.packages
              }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'postcards' ) {
          return (
            <PostcardsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              postcards={ pageSegment.postcards }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'processSteps' ) {
          return (
            <ProcessStepsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              processSteps={ pageSegment.processSteps }
              bodyText={ pageSegment.bodyText }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'processStepsSimple' ) {
          return (
            <ProcessStepsSimpleSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              processStepsSimple={ pageSegment.processStepsSimple }
              bodyText={ pageSegment.bodyText }
              button={ pageSegment.button }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'propertyListings' ) {
          return (
            <PropertyListingsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              additionalBottomPadding={ additionalBottomPadding }
              buttonLabel={ pageSegment.buttonLabel }
              buttonLink={ pageSegment.buttonLink }
              company={ pageSegment.company }
              related={ pageSegment.related }
              numberOfProperties={ pageSegment.numberOfProperties }
              showCountdown={ pageSegment.showCountdown }
              propertyCardStyle={ pageSegment.propertyCardStyle }
              carouselForSmallScreens={ pageSegment.carouselForSmallScreens }
              showLoadMoreButton={ pageSegment.showLoadMoreButton }
              showFilterControls={ pageSegment.showFilterControls }
            />
          )
        }

        if ( pageSegment.typeHandle === 'properties' ) {
          return (
            <PropertyListingsLargeSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'story' ) {
          return (
            <StorySegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              additionalBottomPadding={ additionalBottomPadding }
              label={ pageSegment.label }
              buttonLabel={ pageSegment.button?.text }
              buttonLink={ pageSegment.button?.url }
              buttonTarget={ pageSegment.button?.target }
              image={ pageSegment.mainImage[ 0 ] }
            />
          )
        }

        if ( pageSegment.typeHandle === 'testimonials' ) {
          return (
            <TestimonialsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              testimonials={ pageSegment.testimonials }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'textCta' ) {
          return (
            <TextCtaSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              subtitle={ pageSegment.subtitle }
              theme={ pageSegment.ctaTheme }
              icon={ pageSegment.ctaIcon }
              buttons={ pageSegment.buttonGroup.map( b => ( {
                id: b.id,
                href: b.buttonLink,
                label: b.buttonLabel,
                primary: b.primary,
                openInNewTab: b.openInNewTab,
              } ) ) }
              additionalBottomPadding={ additionalBottomPadding }
            />
          )
        }

        if ( pageSegment.typeHandle === 'videoCta' ) {
          return (
            <VideoCtaSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              description={ pageSegment.description }
              coverImage={ pageSegment.mainImage[ 0 ] }
              buttonLabel={ pageSegment.buttonLabel }
              videoUrl={ pageSegment.videoUrl }
            />
          )
        }

        if ( pageSegment.typeHandle === 'highlights' ) {
          return (
            <HighlightsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              highlights={ pageSegment.highlights }
            />
          )
        }

        if ( pageSegment.typeHandle === 'floorplan' ) {
          return (
            <FloorplanSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              image={ pageSegment.mainImage?.[ 0 ] }
              table={ pageSegment.floorplanTable }
              footer={ pageSegment.floorplanFooter }
            />
          )
        }

        if ( pageSegment.typeHandle === 'localArea' ) {
          return (
            <LocalAreaSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              distances={ pageSegment.distances }
              location={ pageSegment.location }
              subtitle={ pageSegment.subtitle }
              bodyText={ pageSegment.bodyText }
              image={ pageSegment.mainImage?.[ 0 ] }
            />
          )
        }

        if ( pageSegment.typeHandle === 'columns' ) {
          return (
            <ColumnsSegment
              key={ pageSegment.id }
              anchorId={ anchorId }
              title={ pageSegment.mainTitle }
              columns={ pageSegment.textColumns }
            />
          )
        }

        return null
      } )}
      {after}
    </StyledPageSegments>
  )
}

const StyledPageSegments = styled.div`
  @media (min-width: 900px) {
    ${ StyledVideoCta } + ${ Background } {
      padding-top: calc(6 * var(--stack-basis));
    }

    ${ Background } + ${ StyledVideoCta } {
      padding-top: calc(12 * var(--stack-basis));
      margin-top: calc(-12 * var(--stack-basis));
    }

    ${ StyledVideoCta } {
      padding-top: calc(6 * var(--stack-basis));
    }
  }

  /**
  If there's a document page style header, collapse the top margin of the first page segment
  */
  .bx-page-document-header + & > {
    .bx-page-segment:first-child > div > ${ Container } > ${ Stack },
    .bx-page-segment:first-child > div > section > ${ Container } > ${ Stack } {
      padding-top: 0;
    }
  }

  /**
  if two adjecent page segments have the same BG color, collapse padding
  with exceptions for specific page segment types:
  - cards
  - infoPoints
  - largePostcards
  - packageCards
  - testimonials
  */
  .bx-background--color_transparent:not(.bx-page-segment-cards):not(
      .bx-page-segment-largePostcards
    ):not(.bx-page-segment-infoPoints):not(.bx-page-segment-testimonials)
    + .bx-background--color_transparent,
  .bx-background--color_ivory:not(.bx-page-segment-cards):not(
      .bx-page-segment-largePostcards
    ):not(.bx-page-segment-infoPoints):not(.bx-page-segment-testimonials):not(.bx-page-segment-highlights)
    + .bx-background--color_ivory {
    > div > ${ Container } > ${ Stack },
    > div > section > ${ Container } > ${ Stack } {
      padding-top: 0;
    }
  }

  /**
  Line border for specific page segment types:
  - cards
  - infoPoints
  - testimonials
  */
  .bx-background--color_transparent.bx-page-segment-cards
    + .bx-background--color_transparent,
  .bx-background--color_transparent.bx-page-segment-infoPoints
    + .bx-background--color_transparent,
  .bx-background--color_transparent.bx-page-segment-testimonials
    + .bx-background--color_transparent,
  .bx-background--color_ivory.bx-page-segment-cards
    + .bx-background--color_ivory,
  .bx-background--color_ivory.bx-page-segment-infoPoints
    + .bx-background--color_ivory,
  .bx-background--color_ivory.bx-page-segment-testimonials
    + .bx-background--color_ivory,
  .bx-background--color_ivory.bx-page-segment-highlights
    + .bx-background--color_ivory,
  .bx-background--color_realWhite.bx-page-segment-intro
    + .bx-background--color_realWhite {
    border-top: 1px solid ${ p => p.theme.colors.grey300 };
  }

  .bx-background--color_green200.bx-page-segment-documents:last-child {
    border-bottom: 1px solid ${ p => p.theme.colors.green400 };
  }

  /* .bx-background--color_transparent:not( .bx-page-segment-packageCards ) 
  + .bx-background--color_transparent.bx-page-segment-with-title,
  .bx-background--color_ivory + .bx-background--color_ivory {
    border-top: 1px solid ${ p => p.theme.colors.grey300 };
  } */
`

export default PageSegments
